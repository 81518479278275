<template>
  <div>
    <el-button type="primary" round @click="isShowAdd">新增大区</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="id" label="#" width="60">
      </el-table-column>
      <el-table-column prop="name" label="大区" >
      </el-table-column>
      <el-table-column  label="操作" width="300">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加 -->
    <el-dialog title="新增大区" :visible.sync="dialogVisibleAdd" width="40%">
      <span>新增大区名称</span>
      <el-input v-model="add"></el-input>
      <span>权重(纯数字)</span>
      <el-input v-model="weight" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="addDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="dialogVisibleEdit" width="40%">
      <span>编辑大区名称</span>
      <el-input v-model="add"></el-input>
      <span>权重(纯数字)</span>
      <el-input v-model="weight" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="EditDetermine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      add: "",
      weight: "",
      dialogVisibleAdd: false,
      dialogVisibleEdit: false,
      id: ""
    };
  },
  created() {
    this.getRegionList();
  },
  methods: {
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    async getRegionList() {
      this.openFullScreen2();
      const res = await this.axios.get("api/backend/region");
      console.log(res);
      if (res.status_code === 200) {
        this.loading.close();
        this.tableData = res.data;
      } else if (res.status_code === 401) {
        this.loading.close();
        this.$router.push({ path: "/login" });
      }
    },
    isShowAdd() {
      this.dialogVisibleAdd = true;
      this.add = "";
      this.weight = "";
    },
    async addDetermine() {
      const res = await this.axios.post("api/backend/region", {
        name: this.add,
        weight: this.weight
      });
      if (res.status_code === 200) {
        this.dialogVisibleAdd = false;
        this.$message.success("添加成功");
        this.getRegionList();
        this.add = "";
        this.weight = "";
      } else {
        // this.$message.error(res.error);
      }
    },
    handleEdit(row) {
      console.log(row);
      this.dialogVisibleEdit = true;
      this.id = row.id;
      this.add = row.name;
      this.weight = row.weight;
    },
    async EditDetermine() {
      const res = await this.axios.put(`api/backend/region/${this.id}`, {
        name: this.add,
        weight: this.weight
      });
      console.log(res);
      if (res.status_code === 200) {
        this.dialogVisibleEdit = false;
        this.$message.success("修改成功");
        this.getRegionList();
        this.add = "";
        this.weight = "";
      } else {
        this.$message.error(res.error);
      }
    },
    handleDelete(row) {}
  }
};
</script>

